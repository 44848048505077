/* HowItWorksSection.css */

.howitworks-section {
    padding: 100px 50px;
    background-color: #F3EFE8; /* Same as hero section */
  }
  
  .howitworks-section h2 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 36px;
    color: #000000;
  }
  
  .steps-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .step {
    width: 30%;
    text-align: center;
  }
  
  .step-number {
    width: 60px;
    height: 60px;
    background-color: #000000;
    color: #FFFFFF;
    border-radius: 50%;
    display: inline-block;
    line-height: 60px;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .step h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .step p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  
  /* Responsive */
  @media screen and (max-width: 768px) {
    .steps-container {
      flex-direction: column;
    }
  
    .step {
      width: 100%;
      margin-bottom: 40px;
    }
  }