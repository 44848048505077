/* FeaturesSection.css */

.features-section {
    padding: 100px 50px;
    background-color: #FFFFFF;
  }
  
  .features-section h2 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 36px;
    color: #000000;
  }
  
  .feature {
    display: flex;
    align-items: center;
    margin-bottom: 80px;
  }
  
  .feature:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  .feature img {
    width: 50%;
    border-radius: 10px;
  }
  
  .feature-text {
    width: 50%;
    padding: 0 30px;
  }
  
  .feature-text h3 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .feature-text p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  .small-features {
    display: flex;
    justify-content: space-between;
  }
  
  .small-feature {
    width: 48%;
    text-align: center;
  }
  
  .small-feature i {
    color: #000000;
    margin-bottom: 20px;
  }
  
  .small-feature h4 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .small-feature p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Responsive */
  @media screen and (max-width: 992px) {
    .feature {
        flex-direction: column;
        max-width: 85%;
        align-items: center;
        text-align: center;
        display:contents;
        padding: 30px;
      }
      .feature img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        align-self: center;
        padding-bottom: 10px;
      }
      .feature-text {
        width: 100%;
        padding: 0;
        padding-bottom: 10px;
      }
    .small-features {
      flex-direction: column;
    }
  
    .small-feature {
      width: 100%;
      margin-bottom: 40px;
    }
  }