/* HeroSection.css */

.hero-section {
    position: relative;
    display: flex;
    align-items: center;
    padding: 120px 50px 100px 50px;
    background-color: #F3EFE8;
    overflow: hidden;
  }
  
  /* Add background shapes */
  .hero-section::before {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    width: 300px;
    height: 300px;
    background-color: rgba(244, 235, 208, 0.8); /* Darker color */
    border-radius: 50%;
  }
  
  .hero-section::after {
    content: '';
    position: absolute;
    bottom: -50px;
    right: -50px;
    width: 400px;
    height: 400px;
    background-color: rgba(244, 235, 208, 0.6); /* Darker color */
    border-radius: 50%;
  }
  
  .hero-content {
    flex: 1;
    z-index: 1;
  }
  
  .hero-content h1 {
    font-size: 60px;
    line-height: 1.2;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .hero-content h2 {
    font-size: 36px;
    line-height: 1.3;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 20px;
    line-height: 1.6;
    color: #000000;
    margin-bottom: 30px;
  }
  
  .cta-button {
    background-color: #F4EBD0;
    color: #000000;
    padding: 15px 40px;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    border: none;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #e2d6b8;
  }
  
  .hero-image {
    flex: 1;
    text-align: center;
    z-index: 1;
  }
  
  .hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .free-text {

    font-size: 0.9rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.7); /* Adjust for your color scheme */
    margin-top: 20px;
  }


  /* Responsive */
  @media screen and (max-width: 992px) {
    .hero-section {
        flex-direction: column;
        text-align: center;
      }
    
      .hero-content {
        order: 2; /* Display after the image */
      }
    
      .hero-image {
        order: 1; /* Display before the content */
        margin-bottom: 30px;
      }
    
    .hero-content h1 {
      font-size: 48px;
    }
  
    .hero-content h2 {
      font-size: 28px;
    }
  
  }