/* WaitlistSection.css */

.waitlist-section {
  padding: 100px 50px;
  background-color: #F4EBD0;
  text-align: center;
}

.waitlist-section h2 {
  font-size: 36px;
  margin-bottom: 40px;
  color: #000000;
}

.waitlist-section p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.waitlist-form {
  max-width: 600px;
  margin: 0 auto;
}

.waitlist-form input {
  width: 90%;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
}

.form-controls {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adds space between the checkbox container and the button */
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px; /* Smaller gap between checkbox and label */
  margin-bottom: 20px;
  width: fit-content; /* Restrict the container's width */
  margin-left: 20px;
  padding-right: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin: 0;
  padding-right: 10px;
  transform: scale(1.5); /* Enlarges the checkbox */
  width: auto; /* Ensures the checkbox has no extra space around it */
}

.checkbox-label {
  font-size: 14px;
  color: #000000;
  margin: 0;
  /* white-space: nowrap; Ensures the label stays on the same line as the checkbox */
}

.waitlist-form button {
  padding: 15px;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 18px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.waitlist-form button:hover {
  background-color: #333333;
}

.form-message {
  margin-top: 20px;
  font-size: 16px;
  color: #000000;
}

/* Responsive */
@media screen and (max-width: 576px) {
  .waitlist-section h2 {
    font-size: 28px;
  }

  .waitlist-section p {
    font-size: 16px;
  }

  .waitlist-form button {
    font-size: 16px;
  }
  .checkbox-container {
    margin-left: 10px;
  }
}