/* TeamSection.css */

.team-section {
    padding: 100px 50px;
    background-color: #FFFFFF;
  }
  
  .team-section h2 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 36px;
    color: #000000;
  }
  
  .team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .team-member {
    width: 30%;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .team-member img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .team-member h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .team-member .title {
    font-size: 18px;
    color: #666666;
    margin-bottom: 15px;
  }
  
  .team-member .bio {
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    padding: 0 10px;
  }
  
  /* Responsive */
  @media screen and (max-width: 992px) {
    .team-member {
      width: 45%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .team-member {
      width: 100%;
    }
  }