/* PricingSection.css */

.pricing-section {
    padding: 100px 50px;
    background-color: #FFFFFF;
  }
  
  .pricing-section h2 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 36px;
    color: #000000;
  }
  .register{ 
    text-decoration: none;
    background-color: #000000;
    color: #FFFFFF;
    padding: 15px 30px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-top: auto;
    max-width: 20%;
    align-self: center;
  }
  .pricing-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
  }
  
  .plan {
    background-color: #F3EFE8;
    padding: 30px;
    text-align: center;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .plan.popular {
    background-color: #F4EBD0;
  }
  
  .plan .badge {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000000;
    color: #FFFFFF;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 14px;
  }
  
  .plan h3 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .plan .price {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .plan .price span {
    font-size: 18px;
  }
  
  .plan ul {
    list-style: none;
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
    flex-grow: 1;

  }
  
  .plan ul li {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .plan button {
    background-color: #000000;
    color: #FFFFFF;
    padding: 15px 30px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-top: auto;
  }
  
  .plan button:hover {
    background-color: #333333;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .pricing-container {
      grid-template-columns: 1fr;
    }
  }