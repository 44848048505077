/* App.css */

/* Global Styles */
/* App.css */

/* Global Styles */
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    color: #000000;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  #root {
    width: 100%;
    overflow-x: hidden;
  }
  
  /* Ensure all sections take full width */
  section {
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #F3EFE8;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #F4EBD0;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #b3aca0;
  }