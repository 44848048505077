/* Navbar.css */

/* General Navbar Styles */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    

  }
  
  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  /* Navbar Scrolled State */
  .navbar.scrolled {
    background-color: #000000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Navbar Logo */
  .navbar-logo img {
    height: 40px;
    transition: filter 0.3s ease;
  }
  
  /* Navbar Menu */
  .navbar-menu {
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 40px;
    margin-top: 0;
  }
  
  .navbar-menu li {
    margin-right: 20px;
  }
  
  .navbar-menu a {
    text-decoration: none;
    font-weight: 500;
    color: #000000;
    font-size: 20px;
    transition: color 0.3s ease;
  }
  
  .navbar.scrolled .navbar-menu a {
    color: #FFFFFF;
  }
  
  /* .navbar-menu a:hover {
    color: #F4EBD0;
  } */
  
  /* Hamburger Menu */
  .navbar-toggle {
    display: none;
    font-size: 24px;
    cursor: pointer;
    color: #000000;
    margin-right: 15px;
  }
  
  .navbar.scrolled .navbar-toggle {
    color: #FFFFFF;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .navbar-menu {
      position: fixed;
      top: 70px;
      left: -100%;
      height: calc(100% - 70px);
      width: 200px;
      background-color: #000000;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      transition: left 0.3s ease;
      z-index: 999;
    }
  
    .navbar-menu.active {
      left: 0;
    }
  
    .navbar-menu li {
      margin: 15px 0;
    }
  
    .navbar-menu a {
      color: #FFFFFF;
      font-size: 18px;
    }
  
    .navbar-toggle {
      display: block;
    }
  }