/* AboutUsSection.css */

.aboutus-section {
    padding: 100px 50px;
    background-color: #F3EFE8;
    text-align: center;
  }
  
  .aboutus-section h2 {
    font-size: 36px;
    margin-bottom: 40px;
    color: #000000;
  }
  
  .aboutus-section p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 50px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .aboutus-section img {
    max-width: 30%;
    height: auto;
    border-radius: 10px;
  }
  
  /* Responsive */
  @media screen and (max-width: 768px) {
    .aboutus-section p {
      font-size: 16px;
    }
    .aboutus-section img {
      max-width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }