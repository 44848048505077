/* ContactSection.css */

.contact-section {
  padding: 100px 50px;
  background-color: #FFFFFF;
}

.contact-section h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 36px;
  color: #000000;
}

.contact-section p {
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 50px;
  color: #000000;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contact-details {
  width: 40%;
}

.contact-details p {
  font-size: 16px;
  margin-bottom: 20px;
}

.contact-details .social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0; /* Remove extra padding */
}

.contact-details .social-icons a {
  margin-right: 15px;
  font-size: 24px;
  color: #000000;
}

.contact-details .social-icons a:hover {
  color: #F4EBD0;
}

.contact-form {
  width: 55%;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
}

.contact-form textarea {
  resize: vertical;
  min-height: 150px;
}

.contact-form button {
  width: 100%;
  padding: 15px 30px;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 18px;
  border: none;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #333333;
}

.form-controls {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adds space between the checkbox container and the button */
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px; /* Smaller gap between checkbox and label */
  margin-bottom: 20px;
  width: fit-content; /* Restrict the container's width */
  margin-left: 20px;
  padding-right: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin: 0;
  padding-right: 10px;
  transform: scale(1.5); /* Enlarges the checkbox */
  width: auto; /* Ensures the checkbox has no extra space around it */
}

.checkbox-label {
  font-size: 14px;
  color: #000000;
  margin: 0;
  /* white-space: nowrap; Ensures the label stays on the same line as the checkbox */
}


.form-message {
  margin-top: 20px;
  font-size: 16px;
  color: #000000;
}

/* Responsive */
@media screen and (max-width: 992px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-details,
  .contact-form {
    padding: 0px;
    width: 90%;
    align-items: center;
    align-self: center;
  }
  .contact-details .social-icons {
    display: flex;
    justify-content: center; /* Center the social icons */
    padding-left: 0; /* Remove unnecessary padding */
  }

  .contact-details {
    margin-bottom: 50px;
  }
}