/* Footer.css */

.footer {
    background-color: #000000;
    color: #FFFFFF;
    padding: 30px 50px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer .quick-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .footer .quick-links a {
    color: #FFFFFF;
    text-decoration: none;
    margin: 5px 15px;
    font-size: 16px;
  }
  
  .footer .quick-links a:hover {
    color: #F4EBD0;
  }
  
  .footer .social-media {
    margin-bottom: 20px;
  }
  
  .footer .social-media a {
    color: #FFFFFF;
    font-size: 24px;
    margin: 0 10px;
  }
  
  .footer .social-media a:hover {
    color: #F4EBD0;
  }
  
  .footer p {
    font-size: 14px;
  }
  
  /* Responsive */
  @media screen and (max-width: 576px) {
    .footer .quick-links a {
      font-size: 14px;
    }
  
    .footer .social-media a {
      font-size: 20px;
    }
  }